<script>
import UserForm from "@/views/menu/user/userForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    userService: "userService",
    visitorLevelService: "visitorLevelService",
  },
})
export default class UserNew extends mixins(UserForm) {
  formId = "new-user-form";
  isSaving = false;
  get fieldColumns() {
    return 3;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
        disabled: () => {
          return this.isSaving;
        },
      },
      {
        id: "saveWithEmail",
        type: "submit",
        text: this.translations.labels.button_saveWithEmail,
        leftIcon: "mdi-email-edit-outline",
        handle: this.saveWithEmail,
        disabled: () => {
          return this.isSaving;
        },
      }
    ];
  }

  async saveWithEmail(action, passesValidation) {
    await this.save(action, passesValidation, true);
  }

  async save(action, passesValidation, sendEmail = false) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }

    try {
      this.isSaving = true;
      this.model.idLanguage = this.model.language.idLanguage;
      this.model.cultureCode = this.model.language.cultureCode;
      this.model.sendUserCompleteRegistrationEmail = sendEmail;
      this.model.expirationDate =
        this.model.expirationDate != "" ? this.model.expirationDate : null;

      this.model.customFields = this.formatCustomFieldsToSave(
        this.model.userCustomFields
      );

      await this.userService.create(this.model);

      this.isSaving = false;
      this.$router.push("/user/list");
      toastService.success(this.translations.success.user_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.users_new;
  }

  async afterCreate() {
    this.setVisitorLevels(
      await this.visitorLevelService.enabledUserLevels(this.model.id)
    );
    this.model = {
      language: {
        idLanguage: null,
        cultureCode: null,
      },
      cultureCode: null,
      firstName: null,
      lastName: null,
      email: null,
      mobilePhone: null,
      username: null,
      businessCard: null,
      publicBusinessData: null,
      birthDate: null,
      telephone: null,
      company: null,
      profession: null,
      country: null,
      province: null,
      city: null,
      address: null,
      zipCode: null,
      expirationDate: null,
      idGroupList: [],
      visitorLevels: this.defaultVisitorLevelsValue(),
    };
    this.model.userCustomFields = this.prepareCustomFieldsModel(
      this.model.customFields
    );
    this.isReady = true;
  }
}
</script>
